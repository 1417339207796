.placeholder-text::placeholder {
    color: #9E9E9E;
}

.custom-shadow {
    box-shadow: 0 0 40px 0 #EAE6FF;
}

.custom-shadow2 {
    box-shadow: 0 0 40px 0 #EAE6FF;
}

/* ::-webkit-scrollbar{
    display: none;
} */