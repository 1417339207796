#slider-boxes {
  display: flex m !important;
}

.slick-slide .item2 {
  margin: 0 15px;
}

.t-section .slick-prev {
  top: 33rem;
  left: 36.2rem;
}

.t-section .slick-next {
  top: 33rem;
  left: 40.2rem;
}

@media (max-width: 1439px) {
  .slick-slide .item2 {
    margin: 0 20px;
  }

  .t-section .slick-prev {
    top: 34rem;
    left: 19.7rem;
  }

  .t-section .slick-next {
    top: 34rem;
    left: 23.7rem;
  }
}

@media (max-width: 767px) {
  .t-section .slick-slide .item2 {
    margin: 0 8px;
  }
  .t-section .slick-slide:first-child .item2 {
    margin: 0 0 0 16px;
  }

  .t-section .slick-slide:last-child .item2 {
    margin: 0 16px 0 0;
  }

  .t-section .slick-dots {
    margin: -35px 0;
    padding-top: "20px";
  }

  .t-section .slick-dots li {
    margin: 0 -4px;
  }
}

.slick-arrow:before {
  display: none;
}

.slick-slide {
  padding-bottom: 5px;
  padding-top: 5px;
}
