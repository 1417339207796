@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}

body {
  @apply leading-[normal] m-0;
}

.section-transition:not(:first-of-type) {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease-out;
}

.section-visible:not(:first-of-type) {
  opacity: 1;
  transform: translateY(0);
}

.fade-in {
  animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
  filter: brightness(1.05);
  transition: all 0.3s ease;
}

.floating {
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
  0% { transform: translate(0, 0px); }
  50% { transform: translate(0, 15px); }
  100% { transform: translate(0, 0px); }
}

.cta-pulse {
  animation: pulse 2s infinite;
}

.animation-pause {
  animation-play-state: paused;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
}
